import { Input, Field } from "@fluentui/react-components";
import { MaskedTextField } from "@fluentui/react";
import { OmniFieldLabel } from '../FieldLabel/OmniFieldLabel'
import { useRef, useState } from 'react';
import { useEffect } from "react";
import { useFieldChangeWatcherContext } from "../../../../Context/Controls/FieldChangeWatcherProvider"
import '../../../../Assets/Styles/Shared/Controls/OmniTextBox.css'
import '../../../../Assets/Styles/Shared/Controls/OmniLabel.css'


//withoutComas={true} - para colocar numero sin comas - solo con type number
//decimals={2} - para colocar cantidad de decimales - solo con type decimal
//length={10} - cantidad máxima de caracteres



export const OmniTextBox = (props) => {
    const fieldWatcher = useFieldChangeWatcherContext();
    const [valueItem, setvalueItem] = useState(props.value ?? '');
    const initialFieldValue = props?.value?.toString().toUpperCase() ?? '';
    const fieldEdited = useRef(false);
    const [hasPoint, setHasPoint]=useState(false);
    const ConvertToUpperCase = (event) => {
        setFormat(event.target.value);
        if (props.onChange && typeof props.onChange === 'function') {
            props.onChange(event);
        }
        if (props.property && props.edit) {
            let itemUpperCase = event.target.value;
            if (!props.notuppercase && itemUpperCase) {
                itemUpperCase = itemUpperCase.toUpperCase();
            }
            if (props?.regex) {
                let isValid = props.regex.test(itemUpperCase);
                if (isValid) {
                    props.edit(prevEedit => ({ ...prevEedit, [props.property]: itemUpperCase }));
                }
            }
            else {
                props.edit(prevEedit => ({ ...prevEedit, [props.property]: itemUpperCase }));
            }
        }
    };
    const formControlId = props.register?.name;
    const setFormat=(text)=>{
        text=text==null?'':text;
        let inputType=props.inputType==undefined?'':props.inputType;
        switch(inputType.toUpperCase()){
            case 'DECIMAL':
                var originalValue=valueItem;
                var value = text;
                value = value.toString().replaceAll(',','');
                let firstPart=value.split('.')[0];
                let secondPart=value.split('.')[1];
                firstPart=new Intl.NumberFormat().format(firstPart);
                if(value[value.length]=='.'){
                    value=firstPart+'.';
                }
                else if(secondPart==undefined ){
                    value=firstPart;
                    setHasPoint(false);
                }
                else{
                    if(props.decimals===undefined){
                        value=firstPart+'.'+secondPart.substring(0,2);
                    }
                    else if(secondPart.length<=props.decimals){
                        value=firstPart+'.'+secondPart;
                    }
                    else{
                        value=firstPart+'.'+secondPart.substring(0,parseInt(props.decimals));
                    }
                }
                var valueResult=value;
                if(props.length===undefined){
                    setvalueItem(valueResult);
                }
                else if(parseInt(value.replace('.','').replace(',','').length)<=parseInt(props.length)){
                    setvalueItem(valueResult);
                }
                else{
                    setvalueItem(originalValue);
                }
                break;
            case 'NUMBER':
                var originalValue=valueItem;
                var value = text;
                value = value.toString().replaceAll(',','');
                if(props.withoutComas==false){
                    value=new Intl.NumberFormat().format(value);
                }
                var valueResult=value;
                if(props.length===undefined){
                    setvalueItem(valueResult);
                }
                else if(value.length<=parseInt(props.length)){
                    setvalueItem(valueResult);
                }
                else{
                    setvalueItem(originalValue);
                }
                break;
            default:
                let itemUpperCase = text;
                if (!props.notuppercase) {
                    itemUpperCase = itemUpperCase.toUpperCase();
                }
                if (props?.regex) {
                    let isValid = props.regex.test(itemUpperCase);
                    if (isValid) {
                        setvalueItem(itemUpperCase);
                    }
                }
                else {
                    setvalueItem(itemUpperCase);
                }
                if (initialFieldValue === itemUpperCase) {
                    fieldEdited.current = false;
                }
                else {
                    if (!props.inactivefieldEdited) {
                        fieldEdited.current = true;
                    }
                }
                break;
            }
    }

    const keyDown = (event) => {
        let inputType=props.inputType==undefined?'':props.inputType;
        if (event.key === 'Enter') {
            if (props.preventDefault === undefined) {
                event.preventDefault();
            }
            else if (props.preventDefault.toUpperCase() === "TRUE") {
                event.preventDefault();
            }
            if (props.cleanOnblur) {
                setvalueItem('');
            }
        }
        else{
            if(inputType.toUpperCase()=='NUMBER' || inputType.toUpperCase()=='DECIMAL'){
                let character=event.key;
                if(character!=='Backspace' && character!=='Delete' && character!=='ArrowLeft' 
                && character!=='ArrowRight' && character!=='ArrowDown' && character!=='ArrowUp'
                && character!=='Tab'&& character!=='End'&& character!=='Home'){
                    if(isNaN(parseInt(character))&& character!=='.' ){
                        event.preventDefault();
                    }
                    else if(character==='.'){
                        if(hasPoint===false){
                            setHasPoint(true);
                        }
                        else{
                            event.preventDefault();
                        }
                    }
                }
            }
        }
    }

    useEffect(() => {
        if (fieldWatcher.contextControlsRef.current && fieldWatcher.contextControlsRef.current.length > 0) {
            fieldWatcher.contextControlsRef.current = (fieldWatcher.contextControlsRef.current.map(c => {
                if (c.id === formControlId) {
                    return { ...c, edited: fieldEdited.current }
                }
                return c;
            }));
            fieldWatcher.updateHasPendingChanges();
        }
    }, [fieldEdited.current]);

    //init control on Context
    useEffect(() => {
        if (!fieldWatcher.contextControlsRef.current.some(item => item.id === formControlId)) {
            fieldWatcher.contextControlsRef.current = [...fieldWatcher.contextControlsRef.current, { id: formControlId, edited: false }];
        }
        fieldWatcher.updateHasPendingChanges();
    }, []);
    useEffect(() => {
        setvalueItem(props.value ?? '');
        setFormat(props.value);
    }, [props.value]);
    const Blur = (event) => {
        if (props.cleanOnblur) {
            setvalueItem('');
        }
        if (typeof props.blur === 'function') {
            props.blur(event);
        }
        if (props.property && props.edit) {
            props.edit(prevEedit => ({ ...prevEedit, [props.property]: event.target.value }));
        }
    };
    return (
        <>
            <div className={'container-controls'} >
                <Field className={"label-omni"} validationState={props.validationMessage === undefined ? "none" : "error"}
                    validationMessage={props.validationMessage ?? null} >
                    <OmniFieldLabel {...props} formControlId={formControlId} fieldEdited={fieldEdited.current}></OmniFieldLabel>
                    {
                        props.customMask === undefined ?
                            props.blur === undefined ?
                                <Input {...props} style={{ width: props.width }} autoComplete="off" {...props.register} id={formControlId} value={valueItem}
                                    className={`textBox-omni ${props.notuppercase ? '' : 'uppercaseInput'}`}
                                    spellCheck={props.notspellcheck ? false : true}
                                    autoFocus={props.focus} 
                                    onChange={ConvertToUpperCase}
                                    {...props.contentAfter} title=""
                                    tabIndex={props.tabIndex == undefined ? "" : props.tabIndex}
                                    onKeyDown={keyDown}
                                /> :
                                <Input  {...props} style={{ width: props.width }}
                                    autoComplete="off" {...props.register} id={formControlId} value={valueItem}
                                    className={`textBox-omni ${props.notuppercase ? '' : 'uppercaseInput'}`}
                                    spellCheck={props.notspellcheck ? false : true}
                                    autoFocus={props.focus} onChange={ConvertToUpperCase}
                                    {...props.contentAfter} title=""
                                    tabIndex={props.tabIndex == undefined ? "" : props.tabIndex}
                                    onKeyDown={keyDown}
                                    onBlur={Blur} 
                                />
                            :
                            <MaskedTextField mask={props.customMask} autoComplete="off" {...props.register} id={formControlId} value={valueItem}
                                className={`maskedText-omni ${props.notuppercase ? '' : 'uppercaseInput'}`}
                                spellCheck={props.notspellcheck ? false : true}
                                autoFocus={props.focus} onChange={ConvertToUpperCase}
                                tabIndex={props.tabIndex == undefined ? "" : props.tabIndex}
                                onKeyDown={keyDown}
                                {...props.contentAfter} title=""
                                maskChar="" onBlur={Blur}
                            />
                    }
                </Field>
            </div>
        </>
    );
}