import BarButtons from '../../Shared/BarButtons/BarButtons';
import { rightButtons } from "../../../Assets/Constants/BarButtons/Catalog/Suppliers/Supplier"
import { Pivot, PivotItem } from "@fluentui/react";
import { useGeneralStatesContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
const pivotStyles = {
    root: {
        width: '95%'
    }
};
export const Index = () => {
    const { extendedAccounting } = useGeneralStatesContext();
    return (
        <>
            <BarButtons rightButtons={rightButtons} />
            <Pivot className="pivot-center-client" overflowBehavior="menu" styles={pivotStyles}
                selectedKey={null}>
                <PivotItem headerText="Información" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }} >
                </PivotItem>
                <PivotItem headerText="Datos fiscales" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }}>
                </PivotItem>
                <PivotItem headerText="Contactos" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }}>
                </PivotItem>
                <PivotItem headerText="Crédito" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }}>
                </PivotItem>
                <PivotItem headerText="Reabasto" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }}>
                </PivotItem>
                <PivotItem headerText="Adicionales" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }}>
                </PivotItem>
                <PivotItem headerText="Proveedor a pie de camión" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }}>
                </PivotItem>
                {
                    extendedAccounting &&
                <PivotItem headerText="Contabilidad" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }}>
                    </PivotItem>
                }
                <PivotItem headerText="Cambios" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }}>
                </PivotItem>
            </Pivot>

        </>
    )
}