import { useForm } from "react-hook-form";
import Detail from "./Detail";
import BarButtons from "../../Shared/BarButtons/BarButtons";
import { OmniCard } from "../../Shared/Card/OmniCard";
import { leftButtons, rightButtons } from "../../../Assets/Constants/BarButtons/Catalog/Customers/Add";
import {
    AddAsync,
    GetPaymentMethods, GetTaxRegimes, GetUsesCFDI,
    GetCustomerType, GetLinebusiness, GetPermitCreditAsync,
    SetAxiosPrivate,
    rfcRegex, ExistValidateRFCAsync,
    GetLastAccountingAccountAsync
} from '../../../Functions/Catalogs/Customers/CustomerStore'
import { useGeneralStatesContext, useSetStatesContext } from "../../../Functions/Shared/ItemsSelector/ItemSelectorProvider";
import { SuccesMessage,DangerMessage } from "../../../Models/Shared/BarMessages/Messages";
import useAxiosPrivate from "../../../Hooks/Common/useAxiosPrivate";
import { OmniTextBox } from "../../Shared/Controls/TextBox/OmniTextBox";
import { OmniCheckBox } from '../../Shared/Controls/Checkbox/OmniCheckBox'
import { Pivot, PivotItem, Stack } from "@fluentui/react";
import { OmniDropdown } from '../../Shared/Controls/Dropdown/OmniDropdown';
import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';
import { OmniRadioGroup } from '../../Shared/Controls/Radio/OmniRadioGroup';
import { RoutesDetail } from './Tables/RoutesDetail';
import { Domicile } from './Domicile/Domicile';
import { useState,useEffect } from 'react';
import "./../../../Assets/Styles/Shared/Table/DetailTable.css"
import "./../../../Assets/Styles/Catalogs/Client/Client.css"
import { ReactComponent as WorldCuvitek } from '../../../Assets/Images/Icons/World.svg';
import { ReactComponent as MexicoFlagCuvitek } from '../../../Assets/Images/Icons/MexicoFlag.svg';
import { OmniCallout } from '../../Shared/Callout/OmniCallout';
import { AccountingAccount } from "../../Shared/AccountingAccount/AccountingAccount";
const pivotStyles = {
    root: {
        width: '95%'
    }
};
export default function Add() {
    const { setListMessageCatalog, addNewItem, extendedAccounting, baseCustomers, useForeignKey } = useGeneralStatesContext();
    const { register, handleSubmit, setValue } = useForm();
    const initialEmailList = {
        contryId: "151",
        contry: "México",
        processQuotes: "bill",
        typeRfc: "national",
    };
    const [showErrors, setShowErrors] = useState({
        rfc: undefined,
        businessName: undefined,
        creditLimit: undefined,
        creditDays: undefined,
        distanceConsignmentNoteSupplement:undefined
    });
    const [client, setClient] = useState(initialEmailList)
    const changeComponent = useSetStatesContext();
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);
    const [openCallout, setOpenCallout] = useState();
    const submit = async () => {
        setOpenCallout(true);
    };


    const AddConfirm = async () => {
        let errorMessages = [];
        for (const property in showErrors) {
            if (Object.prototype.hasOwnProperty.call(showErrors, property)) {
                const errorValue = showErrors[property];
                if (errorValue) {
                    errorMessages.push(errorValue);
                }
            }
        }
        if (!client.taxRegimeId) {
            errorMessages.push("El régimen fiscal es obligatorio");
        }
        if (!client.useCFDIId) {
            errorMessages.push("El uso del CFDI es obligatorio");
        }
        if (client.haveCredit) {
            if (parseInt(client.creditDays) < 0) {
                errorMessages.push("Los días de crédito deben ser mayor o igual a 0");
                setShowErrors(prevErrors => ({
                    ...prevErrors,
                    creditDays: `Los días de crédito deben ser mayor o igual a 0`
                }));
            }
            if (parseFloat(client.creditLimit.replace(',', '')) <= 0) {
                errorMessages.push("El límite de crédito debe ser mayor a 0");
                setShowErrors(prevErrors => ({
                    ...prevErrors,
                    creditLimit: `El límite de crédito debe ser mayor a 0`
                }));
            }
        }
        if (!client.accountingAccount && extendedAccounting) {
            errorMessages.push("La cuenta contable es obligatoria");
        }
        if (errorMessages.length > 0) {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(errorMessages.map(x => x).join('. '))]);
        }
        else {
            var data = client;
            data.accountingAccount = (extendedAccounting) && client.accountingAccount.endsWith(".") ? client.accountingAccount.slice(0, -1) : client.accountingAccount;
            const amountWithoutSymbol = data?.creditLimit !== undefined && data?.creditLimit !== '' ? data?.creditLimit?.replace(',', '') : 0;
            data.creditLimit = amountWithoutSymbol;
            data.customerTypeId = client.customerTypeId === undefined ? customerType[0].value : data.customerTypeId;
            data.linebusinessId = client.linebusinessId === undefined ? linebusiness[0].value : data.linebusinessId;
            const selectedRadioItem = itemsRadio.find(item => item.value === data.processQuotes);
            const key = selectedRadioItem ? selectedRadioItem.key : 4;
            data.processQuotes = key;
            data.postalCode = client.postalCode;
            if (usesCFDI) {
                const selectedUseCfdi = usesCFDI.find(item => item.value == data.useCFDIId);
                const keyUseCfdi = selectedUseCfdi ? selectedUseCfdi.clue : 1;
                data.useCFDIClue = keyUseCfdi;
            }
            else {
                data.useCFDIClue = '';
            }
            let result = await AddAsync(data);
            if (result.isSuccessful) {
                changeComponent(<Detail />, result.data);
                data.id = result.data;
                addNewItem({ ...data, updateUserName: localStorage.getItem("userName") });
                setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
            }
            else {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
            }
        }
    };
    const profileStackTokens = {
        childrenGap: '8px',
    };
    const itemsRadio = [
        {
            key: "4",
            value: "bill",
            text: "Factura",
        },
        {
            key: "5",
            value: "remission",
            text: "Remisión",
        }
    ];
    const itemsRadioTypeRfc = [
        {
            key: "3",
            value: "national",
            text: <MexicoFlagCuvitek />,
            tooltip: "Nacional"
        },
        {
            key: "4",
            value: "foreign",
            text: <WorldCuvitek />,
            tooltip: "Extranjero",
            class: "marginWorld"
        }
    ];
    const [customerType, setCustomerType] = useState();
    const [linebusiness, setlinebusiness] = useState();
    const [taxRegimes, settaxRegimes] = useState();
    const [usesCFDI, setusesCFDI] = useState();
    const [paymentMethods, setpaymentMethods] = useState();
    const [credit, setCredit] = useState();
    const [typeRfcView, setTypeRfcView] = useState(false);
    const OnLoadDescripcion = async (url, set) => {
        let result = await url();
        if (result.isSuccessful) {
            const resultList = result.data.map((item) => ({
                key: item.clue ?? item.id,
                value: item.id,
                text: item.description
            }));
            set(resultList)
        }
        else {
            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
        }
    }
    const OnGetCredit = async () => {
        let result = await GetPermitCreditAsync();
        if (result.isSuccessful) {
            setCredit({
                permitWithCredit: result?.data?.permitWithCredit ?? false,
                permitDaysCredit: result?.data?.permitDaysCredit ?? false,
                creditLimitPermit: result?.data?.creditLimitPermit ?? false,
            })
        }
        else {
            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
        }
    }
    const ListGetUseCFDI = async (id) => {
        let result = await GetUsesCFDI(id);
        if (result.isSuccessful) {
            const resultList = result.data.map((item) => ({
                key: item.id,
                value: item.id,
                text: item.description,
                clue: item.clue,
            }));
            setusesCFDI(resultList);
            setClient(prev => ({
                ...prev,
                useCFDIId: undefined,
                useCFDI: undefined
            }));
        }
        else {
            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
        }
    }
    const OnLoadTaxRegimes = async (typePerson) => {
        let result = await GetTaxRegimes();
        if (result.isSuccessful) {
            let filteredData = null;
            if (typePerson == 1) {
                filteredData = result.data.filter(item => item.naturalPersonApplies == true);
            }
            else {
                filteredData = result.data.filter(item => item.moralPersonApplies == true);
            }
            if (filteredData.length > 0) {
                const resultList = filteredData.map((item) => ({
                    key: item.clue ?? item.id,
                    value: item.id,
                    text: item.description,
                }));
                settaxRegimes(resultList);
            }
        }
        else {
            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
        }
    }
    const OnLoadAccountingAccountAutoincrement = async () => {
        let result = await GetLastAccountingAccountAsync();
        if (result.isSuccessful) {
            setClient(prev => ({
                ...prev,
                accountingAccount: baseCustomers + '.' + result.data.accountingAccount.toString()
            }))
        }
        else {
            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
        }
    }

    useEffect(() => {
        if (extendedAccounting) {
            OnLoadAccountingAccountAutoincrement();
        }
        OnLoadDescripcion(GetLinebusiness, setlinebusiness);
        OnLoadDescripcion(GetCustomerType, setCustomerType);
        OnLoadTaxRegimes(1);
        OnLoadDescripcion(GetPaymentMethods, setpaymentMethods);
        OnGetCredit();
    }, []);
    useEffect(() => {
        if ((linebusiness && linebusiness.length > 0) && (customerType && customerType.length > 0)) {
            setClient(prev => ({
                ...prev,
                ...DefaultCustomerType(),
                linebusinessId: linebusiness[0].value,
                linebusiness: linebusiness[0].text
            }))
        }
    }, [linebusiness && customerType]);
    function DefaultCustomerType() {
        let customer = customerType.find(x => x.text.trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() === "publico en general");
        if (customer) {
            return {
                customerTypeId: customer.value,
                customerType: customer.text,
            }
        }
        else {
            return {
                customerTypeId: customerType[0].value,
                customerType: customerType[0].text,
            }
        }
    }   
    const onChangeTypeRFC = (event,data) => {
        let typeRfc = data.value;
        if (typeRfc === 'foreign') {
            setClient(prevEedit => ({ ...prevEedit, rfc: 'XEXX010101000', typeRfc:'foreign' }));

        }
        else {
            setClient(prevEedit => ({ ...prevEedit, rfc: 'XAXX010101000', typeRfc: 'national' }));
        }
    };    
    const ChangeHaveCredit = (event, data) => {
        setShowErrors(prevErrors => ({
            ...prevErrors,
            creditLimit: undefined,
            creditDays:undefined
        }));
        setClient(prev => ({ ...prev, creditDays: '', creditLimit: '' }));
    };

    const onBlurRfc = async (event) => {
        let rfcText = event.target.value;
        if (rfcText === '' || (rfcText === 'XAXX010101000' || rfcText === 'XEXX010101000')) {
            setTypeRfcView(true);
            if (rfcText === 'XEXX010101000') {
                setValue('typeRfc', 'foreign');
                setClient(prevEedit => ({ ...prevEedit, rfc: 'XEXX010101000' }));
            }
            else {
                setValue('typeRfc', 'national');
                setClient(prevEedit => ({ ...prevEedit, rfc: 'XAXX010101000' }));
            }
            setShowErrors(prevErrors => ({
                ...prevErrors,
                rfc: undefined
            }));
            OnLoadTaxRegimes(1);
        }
        else {
            setTypeRfcView(false);
            if (rfcRegex.test(rfcText)) {
                let result = await ExistValidateRFCAsync(rfcText)
                if (result.isSuccessful) {
                    setShowErrors(prevErrors => ({
                        ...prevErrors,
                        rfc: undefined
                    }));
                    setClient(prevEedit => ({ ...prevEedit, rfc: rfcText }));
                }
                else {
                    setShowErrors(prevErrors => ({
                        ...prevErrors,
                        rfc: result.errorMessage
                    }));
                    event?.target?.focus();
                }
            }
            else {
                setShowErrors(prevErrors => ({
                    ...prevErrors,
                    rfc: 'El rfc no es válido'
                }));
                event?.target?.focus();
            }
        }
        if (rfcText.length == 13) {
            OnLoadTaxRegimes(1)
        }
        else if (rfcText.length == 12) {
            OnLoadTaxRegimes(0)
        }
    };
    const onBlurBusinessName = (event) => {
        const inputTime = event.target.value;
        if (inputTime) {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                businessName: undefined
            }));
        }
        else {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                businessName: 'La razón social es obligatoria'
            }));
            event?.target?.focus();
        }
    };
    const onBlurCreditLimit = (event) => {
        const text = event.target.value;
        if (parseFloat(text.replace(',', '')) > 0) {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                creditLimit: undefined
            }));
        }
        else {
            setShowErrors(prevErrors => ({
                ...prevErrors,
               creditLimit: `El límite de crédito debe ser mayor a 0`
            }));
            event?.target?.focus();
        }
    };
    const onBlurCreditDays = (event) => {
        const text = event.target.value;
        if (text) {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                creditDays: undefined
            }));
        }
        else {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                creditDays: `Los días de crédito deben ser mayor o igual a 0`
            }));
            event?.target?.focus();
        }
    };
    const onBlurDistanceConsignmentNoteSupplement = (event) => {
        let text = event.target.value;
        let distance = text.replace(",", "");
        let distanceFloat = parseFloat(distance);
        if (distanceFloat < 99999) {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                distanceConsignmentNoteSupplement: undefined
            }));
        }
        else {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                distanceConsignmentNoteSupplement: 'El campo Distancia complemento carta porte debe ser menor a 99999'
            }));
            event?.target?.focus();
        }
    };
    const onBlurAccountingAccount = (event) => {
        ValidText(event, "accountingAccount", "cuenta contable");
    };
    function ValidText(event, property, fild) {
        let text = event.target.value;
        if (text) {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                [property]: undefined
            }));
        }
        else {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                [property]: `El campo ${fild} es obligatorio`
            }));
            event?.target?.focus();
        }
    }


    return (
        <>
        <form onSubmit={handleSubmit(submit)}>
            <BarButtons rightButtons={rightButtons}
                leftButtons={leftButtons} />
            <Pivot className="pivot-center-client" overflowBehavior="menu" styles={pivotStyles}>
                <PivotItem headerText="Información">
                        <div className='container-fluid container-scroll-two-bars'>
                            <div className='row'>
                                <div className='col-md-auto'>
                            <OmniCard title='Datos generales'>
                                <Stack horizontal tokens={profileStackTokens}>
                                    <Stack.Item style={{ width: "50%" }} >
                                            <OmniTextBox required width='90%' maxLength={13} title="R.F.C."
                                                focus
                                                    blur={onBlurRfc} value={client?.rfc}
                                                    validationMessage={showErrors.rfc}/>

                                        </Stack.Item>
                                        {typeRfcView == true &&
                                            <Stack.Item >
                                                <OmniRadioGroup name="typeRfc"
                                                    title="Tipo de RFC"
                                                    orientation="horizontal"
                                                    items={itemsRadioTypeRfc}
                                                    default={client.typeRfc}
                                                    onChange={onChangeTypeRFC}
                                                    propertyId="typeRfc"
                                                    edit={setClient}                                                />
                                            </Stack.Item>
                                        }
                                </Stack>
                                    <OmniTextBox required title="Razón social" maxLength={256} value={client?.businessName} 
                                        property="businessName"
                                            edit={setClient} blur={onBlurBusinessName} validationMessage={showErrors.businessName}/>
                                    <OmniTextBox title="Nombre comercial"  maxLength={100} value={client?.tradeName} property="tradeName"
                                            edit={setClient} blur={true} />

                                        <OmniDropdown title='Tipo de cliente' items={customerType}
                                            property="customerTypeId" name="customerType"
                                            edit={setClient} required 
                                            value={client?.customerType}
                                            selected={client?.customerTypeId}/>
                                        <OmniDropdown title='Giro del negocio' items={linebusiness}
                                            property="linebusinessId" name="linebusiness"
                                            edit={setClient} required
                                            value={client?.linebusiness}
                                            selected={client?.linebusinessId}/>
                            </OmniCard>
                                </div>
                                <div className='col-md-auto'>
                            <OmniCard title='Particulares'>
                                <OmniLabel text='Saldo' value={client?.balance} />
                            </OmniCard>
                            <OmniCard title='Observaciones'>
                                    <OmniTextBox title="Observaciones del cliente" maxLength={80} property="observations"
                                        edit={setClient} value={client?.observations} blur={true}/>
                            </OmniCard>
                                </div>
                            </div>
                        </div>
                </PivotItem>
                <PivotItem headerText="Facturación">
                        <div className='container-fluid container-scroll-two-bars'>
                            <div className='row'>
                                <div className='col-md-auto'>
                                <OmniCard title='Datos generales'>
                                        <Domicile client={client} setClient={setClient} focus={true} />
                                </OmniCard>
                                </div>
                                <div className='col-md-auto'>
                                    <OmniCard title='Predeterminados'>
                                        <OmniDropdown title='Régimen fiscal' items={taxRegimes}
                                            property="taxRegimeId" name="taxRegime"
                                            edit={setClient} required
                                            value={client?.taxRegime}
                                            selected={client?.taxRegimeId} onChangeItem={ListGetUseCFDI}/>
                                        <OmniDropdown title='Uso del CFDI predeterminado' items={usesCFDI}
                                            property="useCFDIId" name="useCFDI"
                                            edit={setClient} required
                                            value={client?.useCFDI}
                                            selected={client?.useCFDIId}  />
                                        <OmniDropdown title='Forma de pago predeterminada' items={paymentMethods}
                                            property="paymentMethodId" name="paymentMethod"
                                            edit={setClient}
                                            value={client?.paymentMethod}
                                            selected={client?.paymentMethodId}  />
                                <OmniTextBox width='48%' title="Distancia para el complemento de la carta porte" 
                                        property="distanceConsignmentNoteSupplement"
                                            edit={setClient} value={client?.distanceConsignmentNoteSupplement} blur={onBlurDistanceConsignmentNoteSupplement} 
                                        inputType={'decimal'}
                                        length={7}
                                            decimals={2}
                                            validationMessage={showErrors.distanceConsignmentNoteSupplement}/>
                                <OmniCheckBox title="Desglosa IEPS"
                                        register={{ ...register("breakdownIEPS") }}
                                        propertyId="breakdownIEPS"
                                        edit={setClient}
                                            propertyChecked={client.breakdownIEPS} />
                                        <OmniCheckBox title="Usa adenda"
                                            register={{ ...register("useAddendum") }}
                                            propertyId="useAddendum"
                                            edit={setClient}
                                            propertyChecked={client.useAddendum} />
                               
                            </OmniCard>
                                </div>
                            </div>
                        </div>
                </PivotItem>
                    <PivotItem headerText="Contacto" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                 
                </PivotItem>
                <PivotItem headerText="Crédito">
                        <div className='container-fluid container-scroll-two-bars'>
                            <div className='row'>
                                <div className='col-md-auto'>
                                <OmniCard title='Datos crediticios'>
                                    {credit &&
                                        <>
                                        {credit?.permitWithCredit === true &&
                                                <OmniCheckBox title="Tiene crédito"
                                                    register={{ ...register("haveCredit") }}
                                                    propertyId="haveCredit"
                                                    edit={setClient}
                                                    propertyChecked={client.haveCredit}
                                                    onChange={ChangeHaveCredit} />
                                        }
                                        {client.haveCredit === true &&
                                            
                                            <Stack horizontal tokens={profileStackTokens}>
                                                {credit?.permitDaysCredit === true &&
                                                    <Stack.Item style={{ width: "50%" }} >
                                                            <OmniTextBox width="93%" title="Días de crédito" blur={onBlurCreditDays}
                                                                focus value={client?.creditDays} property="creditDays" validationMessage={showErrors.creditDays}
                                                                edit={setClient} inputType={'number'}
                                                                length={3}/>
                                                    </Stack.Item>
                                                }
                                                {credit?.creditLimitPermit === true &&
                                                    <Stack.Item >
                                                            <OmniTextBox width="93%" title="Límite de crédito" blur={onBlurCreditLimit} property="creditLimit"
                                                            edit={setClient} value={client?.creditLimit} 
                                                            inputType={'decimal'}
                                                            length={10}
                                                                decimals={2} validationMessage={showErrors.creditLimit}/>
                                                    </Stack.Item>
                                                }
                                                </Stack>
                                            }
                                        </>
                                    }
                            </OmniCard>
                                </div>
                            </div>
                        </div>
                </PivotItem>
                <PivotItem headerText="Venta en ruta">
                        <div className='container-fluid container-scroll-two-bars'>
                            <div className='row'>
                                <div className='col-md-auto'>
                                    <OmniCard title='Preventa'>
                                <OmniRadioGroup name="processQuotes"
                                    title="Procesar cotizaciones a"
                                    orientation="horizontal"
                                    items={itemsRadio}
                                        default={client.processQuotes}
                                        propertyId="processQuotes"
                                        edit={setClient}
                                    register={{ ...register("processQuotes") }} />
                                    </OmniCard>
                                    {client?.routes &&
                                        <OmniCard title='Rutas' large>
                                            <RoutesDetail description={client} setDescription={setClient}
                                                property="routes" />
                                        </OmniCard>
                                    }
                                </div>
                                <div className='col-md-auto'>
                                    <OmniCard title='Validar distancia'>
                                <OmniCheckBox title="El vendedor debe estar en el radio del cliente"
                                        register={{ ...register("validateDistance") }}
                                        propertyId="validateDistance"
                                        edit={setClient}
                                        propertyChecked={client.validateDistance}/>
                            </OmniCard>
                                </div>
                            </div>
                        </div>                        
                </PivotItem>
                    <PivotItem headerText="Direcciones de envío" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                    
                </PivotItem>
                    <PivotItem headerText="Ubicaciones" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                  
                    </PivotItem>
                    {
                        extendedAccounting &&
                        <PivotItem headerText="Contabilidad">
                            <AccountingAccount
                                    edit={setClient}
                                    value={client?.accountingAccount}
                                    blur={onBlurAccountingAccount}
                                    validationMessage={showErrors.accountingAccount}
                                    baseValue={baseCustomers }
                            />
                        </PivotItem>
                    }
                    <PivotItem headerText="Cambios" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                    </PivotItem>
            </Pivot>
        </form>
         {
        <OmniCallout
            open={openCallout}
            updateIsOpenAlert={setOpenCallout}
            leftFunction={AddConfirm}
            title='Confirmación'
            content='¿Desea guardar los cambios?'
            leftButton={'Si'}
            rightButton={'Regresar'}
            backgroundColorLeft={'var(--success)'}
            colorLeft={'white'}
            backgroundColorRight={'var(--secondary)'}
            colorRight={'white'}
            buttonId={'BtnGuardar'}
            barGroupKey={'rightCommandBar'} 
        />
            }
        </>
    )
}
