import React, { useState, useContext, createContext } from "react";
import StatusFilter from "../../../Assets/Constants/Enums/StatusFilter";
import useAxiosPrivate from "../../../Hooks/Common/useAxiosPrivate";
const GetByStatus = '/accountingtax/getbystatus';

const ComponentContext = createContext();
export function useComponentContext() {
    return useContext(ComponentContext);
}

const IdSelectorContext = createContext({});
export function useIdSelectorContext() {
    return useContext(IdSelectorContext);
}

const SetStatesContext = createContext();
export function useSetStatesContext() {
    return useContext(SetStatesContext);
}

const GeneralStatesContext = createContext({});
export function useGeneralStatesContext() {
    const { items } = useContext(GeneralStatesContext);
    return useContext(GeneralStatesContext);
}

const GeneralFunctionsContext = createContext({});
export function useGeneralFunctionsContext() {
    return useContext(GeneralFunctionsContext);
}

export function ItemSelectorProvider({ children }) {
    var getUrl;
    const [component, setComponent] = useState();
    const [id, setId] = useState();
    const [items, setItems] = useState([]);
    const [search, setSearch] = useState(false);
    const [changePivotKey, setChangePivotKey] = useState();
    const [statusFilter, setStatusFilter]=useState(StatusFilter.Active);
    const [textFilter, setTextFilter]=useState();
    const axiosPrivate = useAxiosPrivate();
    const [isOpenAlert, setIsOpenAlert] = useState(false);
    const [itemToBackCallOut, setItemToBackCallOut] = useState();
    const [loadingSkeleton, setLoadingSkeleton] = useState();
    const useForeignKey = localStorage.getItem('useForeignKey') === 'true';
    
    /*Modificar*/
    const useAccounting = false; //localStorage.getItem('useAccounting') === 'true';
    const extendedAccounting = false; //localStorage.getItem('extendedAccounting');
    const baseCustomers = '200.1'; // Clientes
    const baseSuppliers = '202.1'; // Proveedores
    //const baseCreditors = '';



    const [listMessageCatalog, setListMessageCatalog] = useState([]);
    const setStates = (component, id) => {
        setComponent(component);
        if (id !== undefined && id !== null) {
            setId(id);
        }
    }

    const setGetURL = (url) => {
        getUrl = url;
    }
    const addNewItem = (item) => {
        if (statusFilter != StatusFilter.Inactive) {
            let actualItems = items;
            let itemsResult = [item];
            itemsResult.push(...actualItems);
            setItems(itemsResult);
        }
    }
    const updateItem = (item) => {
        let itemsResult = items.map(itemToEdit =>
            itemToEdit.id == item.id
                ? itemToEdit = item
                : itemToEdit
        );
        setItems(itemsResult);
    }
    const updateItemStatus = (itemId) => {
        if (statusFilter != StatusFilter.All) {
            let itemIndex = items.find(item => item.id === itemId.id);
            if (itemIndex) {
                let itemsResult = items;
                itemsResult = items.filter(item => item.id != itemId.id);
                setItems(itemsResult);
            } else {
                let actualItems = items;
                let itemsResult = [itemId];
                itemsResult.push(...actualItems);
                setItems(itemsResult);
            }

        }
    }

    const updateIsOpenAlert = (isOpen) => {
        setIsOpenAlert(isOpen);
    }
    return (
        <ComponentContext.Provider value={component}>
            <IdSelectorContext.Provider value={id}>
                <SetStatesContext.Provider value={setStates}>
                    <GeneralStatesContext.Provider
                        value={{
                            setComponent,
                            items,
                            setItems,
                            statusFilter,
                            setStatusFilter,
                            textFilter,
                            setTextFilter,
                            listMessageCatalog,
                            setListMessageCatalog,
                            id,
                            setId,
                            setGetURL,
                            addNewItem,
                            updateItem,
                            updateItemStatus,
                            isOpenAlert,
                            updateIsOpenAlert,
                            itemToBackCallOut,
                            setItemToBackCallOut,
                            loadingSkeleton,
                            setLoadingSkeleton,
                            useForeignKey,
                            useAccounting,
                            extendedAccounting,
                            baseCustomers,
                            baseSuppliers,
                            search,
                            setSearch,
                            changePivotKey,
                            setChangePivotKey
                        }}>
                        {children}
                    </GeneralStatesContext.Provider>
                </SetStatesContext.Provider>
            </IdSelectorContext.Provider>
        </ComponentContext.Provider>
    );
}




