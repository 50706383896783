import { useState, useEffect } from 'react';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import { Pivot, PivotItem } from '@fluentui/react';
import { useForm, Controller } from 'react-hook-form';
import { OmniCard } from '../../Shared/Card/OmniCard';
import { OmniTextBox } from '../../Shared/Controls/TextBox/OmniTextBox';
import { OmniCheckBox } from '../../Shared/Controls/Checkbox/OmniCheckBox'
import { OmniSelect } from '../../Shared/Controls/Select/OmniSelect';
import { OmniSwitch } from '../../Shared/Controls/Switch/OmniSwitch';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/Warehouse/Add';
import { SuccesMessage, DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
import { useGeneralStatesContext, useSetStatesContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { AddAsync, SetAxiosPrivate } from '../../../Functions/Catalogs/Warehouse/WarehouseStore';
import { GetBranchsAsync } from '../../../Functions/Branchs/BranchStore';
import Detail from './Detail';
import { Weekdays } from '../../../Assets/Constants/Enums/Weekdays';
import { OmniCallout } from '../../Shared/Callout/OmniCallout';
const pivotStyles = {
    root: {
        width: '95%'
    }
};
export default function Add() {
    const { register, handleSubmit, control, getValues } = useForm();
    const { setListMessageCatalog, addNewItem, useAccounting } = useGeneralStatesContext();
    const changeComponent = useSetStatesContext();
    const axiosPrivate = useAxiosPrivate();
    const [listBranch, setListBranch] = useState();
    SetAxiosPrivate(axiosPrivate);

    const [openCallout, setOpenCallout] = useState();
    const submit = async () => {
        setOpenCallout(true);
    };

    const AddConfirm = async () => {
        var data = getValues();
        let errorMessages = [];
        if (data) {
            data.maximumBoxes = data.maximumBoxes && data.maximumBoxes !== ''
                ? data.maximumBoxes.replace(",", "")
                : data.maximumBoxes;
        }
        if (useAccounting &&
            (!data.accountingAccount ||
            !data.accountingAccountCostSale ||
            !data.accountingAccountSale ||
            !data.accountingAccountDiscountSales ||
            !data.accountingAccountReturnSales ||
            !data.accountingAccountPurchases ||
            !data.accountingAccountReturnPurchases))
        {
            errorMessages.push("Los campos contables son obligatorios");
        }
        if (errorMessages.length > 0) {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(errorMessages.map(x => x).join('. '))]);
        }
        else {

            if (useAccounting) {
                // data.accountingAccount = client.accountingAccount.endsWith(".") ? client.accountingAccount.slice(0, -1) : client.accountingAccount;
                data.accountingAccount = data.accountingAccount.endsWith(".") ? data.accountingAccount.slice(0, -1) : data.accountingAccount;
                data.accountingAccountCostSale = data.accountingAccountCostSale.endsWith(".") ? data.accountingAccountCostSale.slice(0, -1) : data.accountingAccountCostSale;
                data.accountingAccountSale = data.accountingAccountSale.endsWith(".") ? data.accountingAccountSale.slice(0, -1) : data.accountingAccountSale;
                data.accountingAccountDiscountSales = data.accountingAccountDiscountSales.endsWith(".") ? data.accountingAccountDiscountSales.slice(0, -1) : data.accountingAccountDiscountSales;
                data.accountingAccountReturnSales = data.accountingAccountReturnSales.endsWith(".") ? data.accountingAccountReturnSales.slice(0, -1) : data.accountingAccountReturnSales;
                data.accountingAccountPurchases = data.accountingAccountPurchases.endsWith(".") ? data.accountingAccountPurchases.slice(0, -1) : data.accountingAccountPurchases;
                data.accountingAccountReturnPurchases = data.accountingAccountReturnPurchases.endsWith(".") ? data.accountingAccountReturnPurchases.slice(0, -1) : data.accountingAccountReturnPurchases;
            }
            let result = await AddAsync(data);
            if (result.isSuccessful) {
                changeComponent(<Detail />, result.data);
                data.id = result.data;
                addNewItem({ ...data, acronym: localStorage.getItem("userName") });
                setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
            }
            else {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
            }
        }
    };
    useEffect(() =>
    {
        const GetBranchs = async () => {
            let result = await GetBranchsAsync();
            if (result.isSuccessful) {
                setListBranch(result.data);
            }
            else {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
            }
        }
        GetBranchs();
    }, [])
    return (
        <>
            <form onSubmit={handleSubmit(submit)}>
                <BarButtons leftButtons={leftButtons} rightButtons={rightButtons} />
                <Pivot className="pivot-center-client " overflowBehavior="menu" styles={pivotStyles}>
                    <PivotItem headerText="Información" >
                        <div className='container-fluid container-scroll'>
                            <div className='row'>
                                <div className='col-md-auto'>
                                    <OmniCard title='Datos generales'>
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-12'>
                                                <OmniTextBox required width="85%" title='Clave' maxLength={3} register={{ ...register('warehouseKey') }} focus />
                                            </div>
                                            <div className='col-lg-6 col-md-12'>
                                                <OmniTextBox required width="85%" title='Nombre' maxLength={64} register={{ ...register('name') }} />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniCheckBox title='Este almacén es bodega' register={{ ...register('isWarehouse') }} />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                {
                                                    listBranch ?
                                                        <OmniSelect required width='100%' title='Sucursal' register={{ ...register('cuvikBranchId') }} items={listBranch} />
                                                        :
                                                        <OmniSelect required disabled width='100%' title='Sucursal' register={{ ...register('cuvikBranchId') }} items={null} />
                                                }
                                            </div>
                                        </div>
                                    </OmniCard>

                                    {/*<OmniCard title='Resurtir almacén'>*/}
                                    {/*    <div className='col-lg-12 col-md-12'>*/}
                                    {/*        <OmniCheckBox title='Se resurten todos los artículos de este almacén'*/}
                                    {/*            register={{ ...register('restockWarehouse') }}*/}
                                    {/*            infoText="Se resurten todos los artículos de este almacén" />*/}
                                    {/*    </div>*/}
                                    {/*</OmniCard>*/}

                                </div>

                                <div className='col-md-auto'>

                                    <OmniCard title='Capacidad de recibo'>
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-12'>
                                                <OmniTextBox value='0' width="85%" inputType="number" length="4" title='Recepciones por día' register={{ ...register('dailyReceptions') }} />
                                            </div>
                                            <div className='col-lg-6 col-md-12'>
                                                <OmniTextBox value='0' inputType="number" length="4" width="85%" title='Toneladas por día' register={{ ...register('maximumTons') }} />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-12'>
                                                <OmniTextBox value='0' inputType="number" length="4" width="85%" title='Volúmen por día' register={{ ...register('maximumVolume') }} />
                                            </div>
                                            <div className='col-lg-6 col-md-12'>
                                                <OmniTextBox value='0' inputType="number" length="8" width="85%" title='Cajas por día' register={{ ...register('maximumBoxes') }} />
                                            </div>
                                        </div>
                                    </OmniCard>
                                    <OmniCard title='Resurtido'>
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-12'>
                                                <OmniTextBox value='0' inputType="number" length="4" width="85%" title='Días de inventario' register={{ ...register('inventoryDays') }} />
                                            </div>
                                        </div>

                                        <div className='row'>
                                            {Weekdays.map((day) => (

                                                <Controller
                                                    key={day.number}
                                                    name={`listDaysReceiveWarehouseResult[${day.number}]`}
                                                    control={control}
                                                    defaultValue={false}
                                                    render={({ field: { onChange, onBlur, value } }) => (
                                                        <div className='col-lg-4 col-md-12'>
                                                            <OmniSwitch
                                                                onChange={onChange}
                                                                onBlur={onBlur}
                                                                label={day.name}
                                                            />
                                                        </div>
                                                    )}
                                                />

                                            ))}
                                        </div>
                                    </OmniCard>

                                </div>
                            </div>
                        </div>    
                    </PivotItem>
                    {
                        useAccounting &&
                        <PivotItem headerText='Contabilidad'>
                            <div className='container-fluid container-scroll'>
                                <div className='row'>
                                    <div className='col-md-auto'>
                                        <OmniCard title='Cuentas contables'>
                                            <div className='col-lg-6 col-md-12'>
                                                <div className='row'>
                                                    <OmniTextBox required
                                                        title='Almacén'
                                                        maxLength={16}
                                                        register={{ ...register('accountingAccount') }}
                                                        focus
                                                        regex={/^(?:[0-9]+(?:\.[0-9]*)?)*$/}
                                                    />
                                                </div>
                                                <div className='row'>
                                                    <OmniTextBox required
                                                        title='Costo de venta'
                                                        maxLength={16}
                                                        register={{ ...register('accountingAccountCostSale') }}
                                                        regex={/^(?:[0-9]+(?:\.[0-9]*)?)*$/}
                                                    />
                                                </div>
                                                <div className='row'>
                                                    <OmniTextBox required
                                                        title='Ventas'
                                                        maxLength={16}
                                                        register={{ ...register('accountingAccountSale') }}
                                                        regex={/^(?:[0-9]+(?:\.[0-9]*)?)*$/}
                                                    />
                                                </div>
                                                <div className='row'>
                                                    <OmniTextBox required
                                                        title='Descuentos sobre ventas'
                                                        maxLength={16}
                                                        register={{ ...register('accountingAccountDiscountSales') }}
                                                        regex={/^(?:[0-9]+(?:\.[0-9]*)?)*$/}
                                                    />
                                                </div>
                                                <div className='row'>
                                                    <OmniTextBox required
                                                        title='Devolución sobre ventas'
                                                        maxLength={16}
                                                        register={{ ...register('accountingAccountReturnSales') }}
                                                        regex={/^(?:[0-9]+(?:\.[0-9]*)?)*$/}
                                                    />
                                                </div>
                                                <div className='row'>
                                                    <OmniTextBox required
                                                        title='Compras'
                                                        maxLength={16}
                                                        register={{ ...register('accountingAccountPurchases') }}
                                                        regex={/^(?:[0-9]+(?:\.[0-9]*)?)*$/}
                                                    />
                                                </div>
                                                <div className='row'>
                                                    <OmniTextBox required
                                                        title='Devolución de compras'
                                                        maxLength={16}
                                                        register={{ ...register('accountingAccountReturnPurchases') }}
                                                        regex={/^(?:[0-9]+(?:\.[0-9]*)?)*$/}
                                                    />
                                                </div>
                                            </div>
                                        </OmniCard>
                                    </div>
                                </div>
                            </div>
                        </PivotItem>
                    }
                    <PivotItem headerText="Cambios" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                    </PivotItem>    
                </Pivot>                                       
            </form>   
            {
                <OmniCallout
                    open={openCallout}
                    updateIsOpenAlert={setOpenCallout}
                    leftFunction={AddConfirm}
                    title='Confirmación'
                    content='¿Desea guardar los cambios?'
                    leftButton={'Si'}
                    rightButton={'Regresar'}
                    backgroundColorLeft={'var(--success)'}
                    colorLeft={'white'}
                    backgroundColorRight={'var(--secondary)'}
                    colorRight={'white'}
                    buttonId={'BtnGuardar'}
                    barGroupKey={'rigthCommandBar'}
                />
            }           
        </>
    );
}
