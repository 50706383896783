import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { ContextualMenuItem } from '@fluentui/react/lib/ContextualMenu';
import { createElement, useState, useRef } from 'react';
import { Panel } from '@fluentui/react/lib/Panel';
import { useNavigate } from "react-router-dom";
import { registerIcons } from '@fluentui/react/lib/Styling';
import { ReactComponent as inactiveCuvitek } from '../../../Assets/Images/Icons/Inactive.svg';
import { ReactComponent as editCuvitek } from '../../../Assets/Images/Icons/Edit.svg';
import { ReactComponent as addCuvitek } from '../../../Assets/Images/Icons/Add.svg';
import { ReactComponent as saveCuvitek } from '../../../Assets/Images/Icons/Save.svg';
import { ReactComponent as returnCuvitek } from '../../../Assets/Images/Icons/Return.svg';
import { ReactComponent as activeCuvitek } from '../../../Assets/Images/Icons/ActiveWithoutFill.svg';
import { ReactComponent as pinCuvitek } from '../../../Assets/Images/Icons/Pin.svg';
import { ReactComponent as photoCuvitek } from '../../../Assets/Images/Icons/Photo.svg';
import { ReactComponent as blockCuvitek } from '../../../Assets/Images/Icons/Block.svg';
import { ReactComponent as trashCuvitek } from '../../../Assets/Images/Icons/Trash.svg';
import { ReactComponent as handPrintCuvitek } from '../../../Assets/Images/Icons/HandPrint.svg';
import { ReactComponent as inactivecontactsuppliercuvitek } from '../../../Assets/Images/Icons/InactiveContactSupplier.svg';
import { useSetStatesContext, useGeneralStatesContext } from "../../../Functions/Shared/ItemsSelector/ItemSelectorProvider";
import { useFieldChangeWatcherContext } from '../../../Context/Controls/FieldChangeWatcherProvider';
import '../../../Assets/Styles/Shared/BarButtons/BarButton.css';
import { OmniCallout } from "../Callout/OmniCallout";
import Role from '../../../Assets/Constants/Enums/Role';

registerIcons({
    icons: {
        'editCuvitek': createElement(editCuvitek),
        'inactiveCuvitek': createElement(inactiveCuvitek),
        'addCuvitek': createElement(addCuvitek),
        'saveCuvitek': createElement(saveCuvitek),
        'returnCuvitek': createElement(returnCuvitek),
        'activeCuvitek': createElement(activeCuvitek),
        'pinCuvitek': createElement(pinCuvitek),
        'photoCuvitek': createElement(photoCuvitek),
        'blockCuvitek': createElement(blockCuvitek),
        'trashCuvitek': createElement(trashCuvitek),
        'handPrintCuvitek': createElement(handPrintCuvitek),
        'inactivecontactsuppliercuvitek': createElement(inactivecontactsuppliercuvitek)
    },
});
const commandBarStyles: IStackStyles = {
    primarySet: {
        flexDirection: 'row-reverse',
    }
};
const CustomMenuItem: React.FunctionComponent<IContextualMenuItemProps> = props => {
    const originItem = props.item;
    const item = {
        ...originItem,
        text: (originItem.text ? originItem.text : 'Boton'),
        disabled: (originItem.disabled ? originItem.disabled : false),
        iconProps: {
            iconName: (originItem.iconProps ? originItem.iconProps.iconName : ''),
            style: {
                fill: (originItem.buttonStyles ? originItem.buttonStyles.icon.fill : 'var(--secondary)'),
            }
        }
    }
    return <ContextualMenuItem {...props} item={item} />;
};
export default function BarButtons(buttons) {
    const changeComponent = useSetStatesContext();
    const { id, itemToBackCallOut, setItemToBackCallOut } = useGeneralStatesContext();
    const [isActive, setIsActive] = useState(false);
    const [openBackCallout, setOpenBackCallout] = useState();
    const [openActiveDeactiveCallOut, setOpenActiveDeactiveCallOut] = useState();
    const [openSaveCallOut, setOpenSaveCallOut] = useState();
    const [itemSaveCallOut, setItemSaveCallOut] = useState();
    const[titleActiveDeactiveCallOut, setTitleActiveDeactiveCallOut]=useState();
    const [itemActiveDeactiveCallOut, setItemActiveDeactiveCallOut] = useState();

    const [openWarningCallOut, setOpenWarningCallOut] = useState();
    const [titleWarningCallOut, setTitleWarningCallOut] = useState();
    const barGroupKeyRef = useRef();
    const fieldWatcher = useFieldChangeWatcherContext();
    let navigate = useNavigate();
    let rigthButtonsItems= [];
    let leftButtonsItems = [];

    var authorizations = JSON.parse(localStorage.getItem("authorizations"));
    var roleId = localStorage.getItem("rolId");
    if (buttons) {
        if (buttons.leftButtons) {
            for (let i = 0; i < buttons.leftButtons.length; i++) {
                if (authorizations != null) {
                    if (buttons.leftButtons[i].authorizationKey !== undefined) {
                        const isAuthorized = (Array.isArray(buttons.leftButtons[i].authorizationKey)) ?
                            buttons.leftButtons[i].authorizationKey.some(key => authorizations.some(auth => auth.authorizationId === key)) :
                            authorizations.some(auth => auth.authorizationId === buttons.leftButtons[i].authorizationKey);

                        if (isAuthorized) {
                            leftButtonsItems.push(CreateButton({ ...buttons.leftButtons[i], barGroupKey: 'leftCommandBar' }));
                        }
                    }
                    else { leftButtonsItems.push(CreateButton({ ...buttons.leftButtons[i], barGroupKey: 'leftCommandBar' })); }
                }
                else if (roleId.toString() === Role.Administrador.toString()){
                    leftButtonsItems.push(CreateButton({ ...buttons.leftButtons[i], barGroupKey: 'leftCommandBar' }));
                }
            }
        }
        if (buttons.rightButtons) {
            for (let i = 0; i < buttons.rightButtons.length; i++) {
                if (authorizations != null) {
                    if (buttons.rightButtons[i].authorizationKey !== undefined) {
                        const isAuthorized = (Array.isArray(buttons.rightButtons[i].authorizationKey)) ?
                            buttons.rightButtons[i].authorizationKey.some(key => authorizations.some(auth => auth.authorizationId === key)) :
                            authorizations.some(auth => auth.authorizationId === buttons.rightButtons[i].authorizationKey);
                        if (isAuthorized || buttons.rightButtons[i].isText) {
                            rigthButtonsItems.push(CreateButton({ ...buttons.rightButtons[i], barGroupKey: 'rigthCommandBar' }));
                        }
                    }
                    else { rigthButtonsItems.push(CreateButton({ ...buttons.rightButtons[i], barGroupKey: 'rigthCommandBar' })); }
                }
                else if (roleId.toString() === Role.Administrador.toString()) {
                    rigthButtonsItems.push(CreateButton({ ...buttons.rightButtons[i], barGroupKey: 'rigthCommandBar' }));
                }
            }
        }
    }
    function CreateButton(item) {
        let menu = {
            key: item.key,
            id: item.key,
            barGroupKey: item.barGroupKey, 
            text: (item.text ? item.text : 'Boton'),
            iconProps: {
                iconName: (item.iconName ? item.iconName : ''),
                style: {
                    paddingBottom: (item.isButton ? '20px' : '')
                }
            },
            type: (item.type ? item.type : 'button'),
            disabled: (item.disabled ? item.disabled : false),
            onClick: () => {
                barGroupKeyRef.current = item.barGroupKey;
                if (item.urlRedirect) {
                    navigate(item.urlRedirect);
                }
                else if (item.openPanel) {
                    setIsActive(!isActive);
                }
                // handler - alertprompt - activar / desactivar
                else if (item.handler) {
                    if (item.alertprompt) {
                        if (item.warning) {
                            setTitleWarningCallOut(item.warning);
                            setOpenWarningCallOut(true);
                            setItemActiveDeactiveCallOut(item);
                            setTitleActiveDeactiveCallOut(item.alertprompt);
                        }
                        else {
                            setTitleActiveDeactiveCallOut(item.alertprompt);
                            setOpenActiveDeactiveCallOut(true);
                            setItemActiveDeactiveCallOut(item);
                        }
                    } else {
                        if (item.key == 'BtnGuardar') {
                            setOpenSaveCallOut(true);
                            setItemSaveCallOut(item);
                        }
                        else {
                            item.handler();
                        }
                    }
                }
                // component - alertprompt
                if (item.component)
                {
                    if(item.component.key == "Detail"){
                        if(fieldWatcher.hasPendingChangesRef.current) {
                            setOpenBackCallout(true);
                            setItemToBackCallOut(item);
                        }
                        else{
                            const componentToChange = item.component.key === "Detail" && id === undefined
                                ? id
                                : item.component;
                            changeComponent(componentToChange, null);
                        }
                    }
                    else{
                        changeComponent(item.component, null);
                     }
                }
                else
                {
                    return false;
                }
            },
            buttonStyles: {
                textContainer: {
                    fontSize: (item.fontSize ? item.fontSize : '15px'),
                    color: (item.fontColor ? item.fontColor : 'var(--dark)'),
                },
                label: {
                    fontWeight: (item.isText ? '600' : 'normal'),
                },
                root: {
                    border: (item?.borderColor ? item.borderColor : 'none'),
                    backgroundColor: (item.backgroundColorButton ? item.backgroundColorButton : '#ffffff'),
                    borderRadius: (item.isButton ? '4px' : ''),
                    width: (item.isButton ? '100px' : ''),
                    height: (item.isButton ? '32px' : ''),
                    marginTop: (item.isButton ? '6px' : ''),
                    marginLeft: (item.isButton ? '6px' : ''),
                    ':active': {
                        opacity: (item.isButton ? '0.5' : ''),
                    }
                },
                rootHovered: {
                    background: (item.backgroundColorButtonHover ? item.backgroundColorButtonHover : '#ffffff'),
                    cursor: (item.isText ? 'default !important' : 'pointer')
                },
                icon: {
                    fill: (item.iconColor ? item.iconColor : 'var(--secondary)')
                },
                iconHovered: { fill: (item.iconHover ? item.iconHover : 'var(--primary)') }
            }
        }
        return menu;
    }  
    function functionNo(){
    }
    function functionOk(){
        const componentToChange = 
        (
            itemToBackCallOut.component.key === "Detail" && id !== undefined
            ? itemToBackCallOut.component
            : null
        );
        changeComponent(componentToChange, id);
    }
    function fucntionOkActiveDeactive() {
        if (typeof itemActiveDeactiveCallOut?.handler === 'function') {
            itemActiveDeactiveCallOut.handler();
        } else {
            changeComponent(itemActiveDeactiveCallOut.handler, id);
        }
    }
    function fucntionOkWarning() {
        setOpenActiveDeactiveCallOut(true);
        setOpenWarningCallOut(false);
    }
    return (
        <>
            <div className='container-fluid'>
                <div className='row row-cols-1 row-cols-sm-2 row-cols-md-2 barButtons'>
                    <div className='col px-0'>
                        <CommandBar id={'commandBarLeft'}
                            items={leftButtonsItems} overflowButtonProps={{
                                menuProps: { contextualMenuItemAs: CustomMenuItem, items: [] },
                                id: 'leftCommandBar'
                            }} />
                    </div>
                    <div className='col px-0'>
                        <CommandBar id={'commandBarRigth'}
                            styles={commandBarStyles}
                            items={rigthButtonsItems} overflowButtonProps={{
                                menuProps: { contextualMenuItemAs: CustomMenuItem, items: [] },
                                id: 'rigthCommandBar'
                            }} />
                    </div>
                </div>
            </div>
            {isActive &&
                <Panel isOpen={isActive}
                    onDismiss={() => (setIsActive((isActive) ? false : true))}
                    headerText="Panel with footer at bottom">
                </Panel>
            }
            { /* Callout Regresar */
                openBackCallout &&
                <OmniCallout
                    open={"true"}
                    updateIsOpenAlert={setOpenBackCallout}
                    leftFunction={functionOk}
                    rightFunction= { functionNo}
                    orientation={5}
                    title='Confirmación'
                    content={"¿Desea salir sin guardar los cambios?"}
                    leftButton={'Si'}
                    rightButton={'No'}
                    backgroundColorLeft={'var(--light-hover)'}
                    colorLeft={'var(--danger)'}
                    backgroundColorRight={'var(--light-hover)'}
                    colorRight={'var(--dark)'}
                    borderLeft={'none'}
                    borderRight={'none'}
                    buttonId={"BtnRegresar"}
                    barGroupKey={barGroupKeyRef.current}
                />
            }
            {/* Callout Activar / Desactivar */
                openActiveDeactiveCallOut &&
                <OmniCallout
                    open={"true"}
                    updateIsOpenAlert={setOpenActiveDeactiveCallOut}
                    leftFunction={fucntionOkActiveDeactive}
                    rightFunction= { functionNo}
                    orientation={5}
                    title='Confirmación'
                    content={titleActiveDeactiveCallOut}
                    leftButton={'Si'}
                    rightButton={'Regresar'}
                    backgroundColorLeft={'var(--success)'}
                    colorLeft={'white'}
                    backgroundColorRight={'var(--secondary)'}
                    colorRight={'white'}
                    buttonId={itemActiveDeactiveCallOut.key!==undefined?itemActiveDeactiveCallOut.key:"btnStatus"}
                    barGroupKey={barGroupKeyRef.current}
                />
            }
            {/* Callout Mensaje Advertencia */
                openWarningCallOut &&
                <OmniCallout
                    open={"true"}
                    updateIsOpenAlert={setOpenWarningCallOut}
                    rightFunction={fucntionOkWarning}
                    orientation={5}
                    title='Advertencia'
                    content={titleWarningCallOut}
                    rightButton={'Ok'}
                    backgroundColorRight={'var(--secondary)'}
                    colorRight={'white'}
                    buttonId={itemActiveDeactiveCallOut.key !== undefined ? itemActiveDeactiveCallOut.key : "btnStatus"}
                    barGroupKey={barGroupKeyRef.current}
                />
            }
            {/* Callout Guardar */
                openSaveCallOut &&
                <OmniCallout
                    open={'true'}
                    updateIsOpenAlert={setOpenSaveCallOut}
                    leftFunction={() => { itemSaveCallOut.handler() }}
                    title='Confirmación'
                    content='¿Desea guardar los cambios?'
                    leftButton={'Si'}
                    rightButton={'No'}
                    backgroundColorLeft={'var(--success)'}
                    colorLeft={'white'}
                    backgroundColorRight={'var(--secondary)'}
                    colorRight={'white'}
                    buttonId={'BtnGuardar'}
                    barGroupKey={'rigthCommandBar'}
                />
            }
        </>
    );
}
