import { OmniTextBox } from '../../Shared/Controls/TextBox/OmniTextBox';
import { OmniCard } from "../../Shared/Card/OmniCard";
import { useGeneralStatesContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';

export const AccountingAccount = (props) => {
    const { extendedAccounting } = useGeneralStatesContext();

    return (
        extendedAccounting &&
        <div className='container-fluid container-scroll-two-bars'>
            <div className='row'>
                <div className='col-lg-6 col-md-12'>
                    <OmniCard title='Contabilidad'>
                        <div className='row'>
                            <OmniTextBox required
                                title='Cuenta contable'
                                maxLength={16}
                                property={"accountingAccount"}
                                edit={props.edit}
                                value={props.value}
                                blur={props.blur}
                                validationMessage={props.validationMessage}
                                focus
                                regex={/^(?:[0-9]+(?:\.[0-9]*)?)*$/}
                            />
                        </div>
                    </OmniCard>
                </div>
            </div>
        </div>
    );
}